'use client';

import React, { useCallback, useMemo, useState } from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { findFilteredLocations, mapStyles } from '@/utils';
import useSWR from 'swr';
import { GroupField } from '@prismicio/client';
import debounce from 'lodash/debounce';
import { useSearchParams } from 'next/navigation';
import clsx from 'clsx';
import Tab from '@/components/atoms/Tab';
import LocationInput from '@/components/molecules/LocationInput';
import LocationList from '@/components/molecules/LocationList';
import MapMarker from '@/components/molecules/MapMarker';
import SearchModal from '@/components/molecules/SearchModal';
import LinkButton from '@/components/atoms/LinkButton';
import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';
import Sort from '@/public/assets/svg/sort.svg';
import useRWD from '@/hooks/useRWD';
import useUserLocation from '@/hooks/useUserLocation';
import { useLang } from '@/hooks/useLang';
import { ROUTES } from '@/routes';
import { getLocations } from '@/utils/swrRequests';
import { defaultMapFilters } from '@/utils/consts';
import { BrowserLocation, Location, MapFiltersType, ServiceType } from '@/utils/types';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { LOCALES } from '@/i18nConfig';
import { pushGTMEvent, pushGTMEventForATM } from '@/utils/gtm';
import { ATM_MAP_MARKER_CLICK_EVENT, ATM_MAP_SEARCH_EVENT } from '@/utils/gtm/consts';
import { useInitialMapCenter } from './hooks';
import { getAvailableCoins } from './utils';
import { clusterStyles, SERVICE_TYPES } from './consts';
type Props = {
  coins: GroupField<Simplify<CoinsDocumentDataCoinsItem>>;
  defaultLocation?: BrowserLocation;
  reduceContainer?: boolean;
  noList?: boolean;
  className?: string;
  mapContainerClassName?: string;
  locationInputClassName?: string;
  zoom?: number;
};
const Map = ({
  coins,
  defaultLocation: defaultLocationProp,
  reduceContainer,
  noList,
  className,
  mapContainerClassName,
  locationInputClassName,
  zoom: zoomProp = 12
}: Props) => {
  const lang = useLang();
  const {
    isDesktop,
    isMobile
  } = useRWD();
  const initialZoom = lang === 'en-hk' ? 15 : zoomProp;
  const [map, setMap] = useState<google.maps.Map>();
  const [openModal, setOpenModal] = useState(false);
  const [isMapActive, setIsMapActive] = useState(true);
  const [tooltipLocationId, setTooltipLocationId] = useState('');
  // This is used to trigger the useMemo to recalculate the filtered locations
  const [locationFilteringTrigger, setLocationFilteringTrigger] = useState(1);
  const searchParams = useSearchParams();
  const longitude = searchParams.get('longitude');
  const latitude = searchParams.get('latitude');
  const placeId = searchParams.get('placeId');
  const filters = useMemo<MapFiltersType>(() => {
    const openNow = searchParams.get('openNow') === 'true';
    const serviceType = searchParams.get('serviceType') || defaultMapFilters.serviceType;
    return {
      openNow,
      serviceType: SERVICE_TYPES.includes(serviceType as ServiceType) ? serviceType as ServiceType : 'all'
    };
  }, [searchParams]);
  const defaultLocationFromParams = useMemo(() => longitude && latitude ? {
    longitude: +longitude,
    latitude: +latitude
  } : undefined, [latitude, longitude]);
  const defaultLocation = defaultLocationProp || defaultLocationFromParams;
  useUserLocation({
    enabled: !!map && !defaultLocation,
    onSuccess: data => map?.setCenter(new google.maps.LatLng({
      lat: data.latitude,
      lng: data.longitude
    }))
  });
  const {
    data: allLocations,
    isLoading
  } = useSWR([ROUTES.APILocations, lang], async () => await getLocations(lang));
  const {
    isLoaded: isMapLoaded
  } = useLoadGoogleMapsScript();
  const initialMapCenter = useInitialMapCenter(defaultLocation, isMapLoaded);
  const [centerPosition, setCenterPosition] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>(initialMapCenter);
  const handleApplyFilters = (newFilters: MapFiltersType) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('openNow', newFilters.openNow.toString());
    params.set('serviceType', newFilters.serviceType);
    setOpenModal(false);
    window.history.replaceState(null, '', `?${params.toString()}`);
  };
  const onChangeLocation = ({
    location,
    id
  }: Location) => {
    map?.setZoom(16);
    map?.setCenter(new google.maps.LatLng({
      lat: +location.latitude,
      lng: +location.longitude
    }));
    setIsMapActive(true);
    setTooltipLocationId(id);
  };
  const filteredLocations = useMemo(() => findFilteredLocations(allLocations?.result || [], filters, lang, map),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [allLocations?.result, filters, map, locationFilteringTrigger]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilteredLocationsTrigger = useCallback(debounce(() => setLocationFilteringTrigger(prev => prev + 1), 100), []);
  return <div className={clsx('mt-3 gap-x-5 lg:min-h-[40.9375rem] lg:grid-cols-[28.75rem_1fr]', reduceContainer && '-mx-6', noList ? 'block' : 'lg:grid', className)} data-sentry-component="Map" data-sentry-source-file="Map.tsx">
      <div className="flex w-full flex-col">
        <div className={clsx('items-center justify-center gap-x-2 px-6 lg:flex lg:px-0', locationInputClassName)}>
          <LocationInput className="mb-3 h-11 w-full lg:mb-5 lg:w-2/3" onSelect={coords => {
          if (typeof coords.latitude !== 'number' || typeof coords.longitude !== 'number') {
            return;
          }
          const center = new google.maps.LatLng({
            lat: coords.latitude,
            lng: coords.longitude
          });
          const zoom = lang === 'en-hk' ? 12 : 15;
          map?.setZoom(zoom);
          map?.setCenter(center);
          setCenterPosition(center);
          setTooltipLocationId('');
          new google.maps.Geocoder().geocode({
            location: {
              lat: coords.latitude,
              lng: coords.longitude
            }
          }, results => {
            if (results?.[0]) {
              pushGTMEvent({
                event: ATM_MAP_SEARCH_EVENT,
                place: results[0].formatted_address,
                selected_button: filters.serviceType === 'buy' ? 'buy-checked' : 'sell-checked'
              });
            }
          });
        }} defaultValue={placeId} isGoogleMapsScriptLoaded={isMapLoaded} data-sentry-element="LocationInput" data-sentry-source-file="Map.tsx" />
          <button onClick={() => setOpenModal(true)} className="mb-7 flex h-11 w-full items-center justify-center gap-x-2 rounded border border-gray-200 bg-white lg:mb-5 lg:w-1/3">
            <Sort data-sentry-element="Sort" data-sentry-source-file="Map.tsx" /> Filters
          </button>
        </div>
        <SearchModal isOpen={openModal} filters={filters} onClose={() => setOpenModal(false)} onApply={handleApplyFilters} data-sentry-element="SearchModal" data-sentry-source-file="Map.tsx" />
        {!noList && <div className="flex w-full lg:hidden">
            <Tab onClick={() => setIsMapActive(false)} active={!isMapActive}>
              List
            </Tab>
            <Tab onClick={() => setIsMapActive(true)} active={isMapActive}>
              Map
            </Tab>
          </div>}
        {!noList && <div className={clsx('flex flex-1 flex-col bg-white lg:bg-inherit', {
        'hidden lg:flex': isMapActive
      })}>
            <div className="px-6 pb-4 pt-4 lg:px-0 lg:pb-8 lg:pt-0">
              {filteredLocations.length} results found
            </div>
            <div className="h-[910px] lg:h-[540px]">
              {filteredLocations.length || isLoading ? <LocationList onChangeLocation={onChangeLocation} locations={filteredLocations} isLoading={isLoading} /> : <div className="animate-fade-in">
                  {lang === LOCALES.EN_CA && <div className="mb-4 rounded-md border border-gray-200 px-5 py-8">
                      <h5 className="mb-2 text-center text-body-5 font-semibold">
                        Looking to sell crypto?
                      </h5>
                      <p className="mb-4 text-center text-body-8 text-gray-500">
                        Sell crypto online for INTERAC e-Transfer
                      </p>
                      <LinkButton href={ROUTES.InteracLink}>Learn More</LinkButton>
                    </div>}
                  <div className="rounded-md border border-gray-200 px-5 py-8">
                    <h5 className="mb-2 text-center text-body-5 font-semibold">
                      Can&apos;t find a convenient ATM ?
                    </h5>
                    <p className="mb-4 text-center text-body-8 text-gray-500">
                      Contact us to request a new location
                    </p>
                    <LinkButton href="/contact">Contact Us</LinkButton>
                  </div>
                </div>}
            </div>
          </div>}
      </div>
      {isMapLoaded ? <GoogleMap onLoad={setMap} onUnmount={() => setMap(undefined)} mapContainerClassName={clsx('w-full lg:rounded-[.625rem]', isDesktop && !noList ? 'h-auto' : 'lg:h-[480px] h-[386px]', isMapActive || isDesktop ? 'visible relative' : 'invisible !absolute', mapContainerClassName)} center={initialMapCenter} zoom={initialZoom} onBoundsChanged={debouncedFilteredLocationsTrigger} clickableIcons={false} options={{
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      styles: mapStyles,
      fullscreenControl: isMobile ? false : true
    }}>
          <div className="absolute left-6 top-4 z-30 flex items-center gap-x-2 rounded-md border border-gray-200 bg-white/60 px-4 py-2.5 text-body-9 shadow-elevation-4 lg:left-4 lg:text-body-8">
            <span className="h-3 w-3 rounded-full bg-green-600 lg:h-4 lg:w-4" />
            <span>Buy</span>
            <span className="w-px text-gray-300">|</span>
            <span className="h-3 w-3 rounded-full bg-primary-600 lg:h-4 lg:w-4" />
            <span>Buy & Sell</span>
          </div>
          <MarkerClusterer minimumClusterSize={2} styles={clusterStyles}>
            {clusterer => <>
                {filteredLocations.map(item => {
            const availableCoins = getAvailableCoins(item, coins);
            return <MapMarker isBuyAndSell={!!availableCoins.sell.length} isMobile={isMobile || false} availableCoins={availableCoins} onClose={() => setTooltipLocationId('')} onClick={event => {
              if (!event.latLng) {
                return;
              }
              setTooltipLocationId(item.id);
              pushGTMEventForATM(item, ATM_MAP_MARKER_CLICK_EVENT);
            }} openedTooltipId={tooltipLocationId} key={item.id} item={item} clusterer={clusterer} />;
          })}
              </>}
          </MarkerClusterer>
          {centerPosition && <Marker position={centerPosition} />}
        </GoogleMap> : <div className="skeleton lg:rounded-[.625rem]" />}
    </div>;
};
export default Map;