'use client';

import React from 'react';
import clsx from 'clsx';
import { useController, useFormContext } from 'react-hook-form';
import { ArrowSwapHorizontalIcon, BtcIcon, DollarBillIcon } from '@/public/assets/svg';
import { useETransferCryptoAmount } from './hooks';
import { CalculatorMobileProps, FormCalculatorMobileProps } from './types';
import { clampValue } from '../CalculatorDesktop/utils';
const CalculatorMobile = ({
  className,
  amount,
  setAmount
}: CalculatorMobileProps) => {
  const btcAmount = useETransferCryptoAmount(amount || 0);
  return <div className={clsx('w-full bg-white', className)} data-sentry-component="CalculatorMobile" data-sentry-source-file="index.tsx">
      <div className="mb-5 flex h-[16.25rem] flex-col">
        <div className={clsx('relative inline-block h-full w-full rounded-[.625rem] border border-gray-200 p-5', `after:absolute after:border after:border-gray-200 after:bg-white after:content-['']`, 'after:bottom-[-1.125rem]', 'after:left-0 after:right-0 after:ml-auto after:mr-auto after:h-9 after:w-[4.25rem]', 'after:rounded-t-full after:rounded-bl-none after:rounded-br-none after:border-b-white')}>
          <p className="mb-3 text-body-8 text-gray-500">You want to receive</p>
          <div className="flex flex-row justify-between">
            <input className="w-28 px-2 text-h6 font-bold" type="number" value={amount || ''} onChange={event => setAmount(event.target.valueAsNumber)} onBlur={event => setAmount(clampValue(event.target.valueAsNumber))} />
            <div className="flex flex-row items-center gap-3">
              <div className="flex items-center justify-center rounded-full bg-gray-100 p-1.5">
                <DollarBillIcon className="h-5 w-5" data-sentry-element="DollarBillIcon" data-sentry-source-file="index.tsx" />
              </div>
              <span className="text-body-5 font-semibold text-gray-600">CAD</span>
            </div>
          </div>
        </div>
        <div className="relative z-30 flex h-8 w-full flex-shrink-0 items-center justify-center bg-white">
          <ArrowSwapHorizontalIcon className="absolute bottom-0 top-0 mb-auto mt-auto h-7 w-7 rotate-90 text-gray-500" data-sentry-element="ArrowSwapHorizontalIcon" data-sentry-source-file="index.tsx" />
        </div>
        <div className={clsx('relative h-full w-full rounded-[.625rem] border border-gray-200 bg-gray-200 p-5', `before:absolute before:border before:border-gray-200 before:bg-white before:content-['']`, 'before:top-[-1.125rem]', 'before:left-0 before:right-0 before:ml-auto before:mr-auto before:h-9 before:w-[4.25rem]', 'before:rounded-b-full before:rounded-tl-none before:rounded-tr-none before:border-t-white')}>
          <p className="mb-3 text-body-8 text-gray-500">You need to send</p>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <span className="text-center text-h6 font-bold text-gray-500">{btcAmount}</span>
            </div>
            <div className="flex flex-row items-center gap-3">
              <div className="flex items-center justify-center rounded-full bg-primary-600 p-1.5">
                <BtcIcon className="h-5 w-5 text-white" data-sentry-element="BtcIcon" data-sentry-source-file="index.tsx" />
              </div>
              <span className="text-body-5 font-semibold text-gray-600">BTC</span>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export const FormCalculatorMobile = ({
  name,
  ...rest
}: FormCalculatorMobileProps) => {
  const {
    field
  } = useController({
    name
  });
  const {
    setValue
  } = useFormContext();
  return <CalculatorMobile amount={field.value} setAmount={value => {
    setValue(name, value);
  }} {...rest} data-sentry-element="CalculatorMobile" data-sentry-component="FormCalculatorMobile" data-sentry-source-file="index.tsx" />;
};
export default CalculatorMobile;