import { MAX_MONEY_AMOUNT, MIN_MONEY_AMOUNT } from '../../organisms/CalculatorWithButton/consts';

export const clampValue = (value: number) => {
  if (value < MIN_MONEY_AMOUNT) {
    return MIN_MONEY_AMOUNT;
  }

  if (value > MAX_MONEY_AMOUNT) {
    return MAX_MONEY_AMOUNT;
  }

  return value;
};
