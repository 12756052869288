import React, { Fragment, useMemo } from 'react';
import { CryptoSlides } from '../CryptoCarousel/types';
import DesktopTable from '../DesktopTable';
import MobileCrypto from '../MobileCrypto';
import DesktopTableSkeleton from '../DesktopTable/partials/DesktopTableSkeleton';
import MobileCryptoSkeleton from '../MobileCrypto/partials/MobileCryptoSkeleton';
type Props = {
  rows?: CryptoSlides | null;
  variant: 'sellFeesAndLimits' | 'buyFeesAndLimits';
  isLoading?: boolean;
};
type KeyValue = {
  [key: string]: CryptoSlides;
};
const FeesTables = ({
  rows,
  variant,
  isLoading
}: Props) => {
  const tables = useMemo(() => {
    const sellFeesAndLimits: KeyValue = {};
    const buyFeesAndLimits: KeyValue = {};
    rows?.forEach(row => {
      row.coin_extras?.forEach(item => {
        if (item.type === 'sell') {
          if (!sellFeesAndLimits[item.service_name as string]) {
            sellFeesAndLimits[item.service_name as string] = [];
          }
          sellFeesAndLimits[item.service_name as string].push({
            ...row,
            service_description: item.service_description,
            service_name: item.service_name
          });
        } else {
          if (!buyFeesAndLimits[item.service_name as string]) {
            buyFeesAndLimits[item.service_name as string] = [];
          }
          buyFeesAndLimits[item.service_name as string].push({
            ...row,
            service_description: item.service_description,
            service_name: item.service_name
          });
        }
      });
    });
    return {
      buyFeesAndLimits,
      sellFeesAndLimits
    };
  }, [rows]);
  if (isLoading) {
    return <>
        <DesktopTableSkeleton />
        <MobileCryptoSkeleton />
      </>;
  }
  return Object.entries(tables[variant]).map(([key, value]) => {
    return <Fragment key={key}>
        <DesktopTable feesRow={key} variant={variant} rows={value} />
        <MobileCrypto feesRow={key} variant={variant} rows={value} />
      </Fragment>;
  });
};
export default FeesTables;