'use client';

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import LinkButton from '@/components/atoms/LinkButton';
import TrendUpIcon from '@/public/assets/svg/fees/trend-up.svg';
import TrendDownIcon from '@/public/assets/svg/fees/trend-down.svg';
import ArrowRightIcon from '@/public/assets/svg/arrow-right.svg';
import { CryptoTableType } from '@/utils/types';
import { PrismicNextImage } from '@prismicio/next';
import { ChevronDownIcon } from '@/public/assets/svg';
import { buttonText } from '../TableBody/consts';
const MobileCrypto: React.FC<CryptoTableType> = ({
  rows: slides,
  variant,
  feesRow,
  isLoading
}) => {
  const [currentCoin, setCurrentCoin] = useState('');
  const centerRows = useMemo(() => {
    return {
      buyTable: slides?.map(item => {
        return [{
          key: 'Buy Price',
          value: item.buyValue
        }, {
          key: 'Flat Fees',
          value: item.buyFeeFlat
        }, {
          key: '24h Change',
          value: <div className="flex h-full items-center gap-x-3">
                  {item.change > 0.0 ? <TrendUpIcon className="text-green-600" /> : item.change.toFixed(2) != '0.00' && <TrendDownIcon className="text-primary-600" />}
                  {item.change.toFixed(2)} %
                </div>
        }];
      }) || [],
      sellTable: slides?.filter(row => row.sellValue !== '-' || variant != 'sellTable')?.map(item => {
        return [{
          key: 'Sell Price',
          value: item.sellValue
        }, {
          key: '24h Change',
          value: <div className="flex h-full items-center gap-x-3">
                    {item.change > 0.0 ? <TrendUpIcon className="text-green-600" /> : item.change.toFixed(2) != '0.00' && <TrendDownIcon className="text-primary-600" />}
                    {item.change.toFixed(2)} %
                  </div>
        }];
      }) || [],
      sellFeesAndLimits: slides?.map(item => {
        const extra_coin = item.coin_extras?.find(coin => coin.service_name == feesRow);
        const transaction_limit = `${extra_coin?.min_transaction_limit} - ${extra_coin?.max_transaction_limit}`;
        return [{
          key: 'Price',
          value: item.sellValue
        }, {
          key: 'Limit Per Transaction',
          value: transaction_limit
        }, {
          key: 'Daily Limit',
          // TODO: convert to number with .00
          value: extra_coin?.daily_limit
        }];
      }) || [],
      buyFeesAndLimits: slides?.map(item => {
        const extra_coin = item.coin_extras?.find(coin => coin.service_name == feesRow);
        const transaction_limit = `${extra_coin?.min_transaction_limit} - ${extra_coin?.max_transaction_limit}`;
        return [{
          key: 'Price',
          value: item.buyValue
        }, {
          key: 'Flat Fee',
          value: item.buyFeeFlat
        }, {
          key: 'Limit Per Transaction',
          value: transaction_limit
        }, {
          key: 'Daily Limit',
          value: extra_coin?.daily_limit
        }];
      }) || []
    }[variant];
  }, [slides, variant, feesRow]);
  if (isLoading) {
    return null;
  }
  return <div className="mb-10 lg:hidden" data-sentry-component="MobileCrypto" data-sentry-source-file="index.tsx">
      {!!feesRow && <h3 className="mb-4 w-full rounded-lg bg-primary-200 py-3.5 text-center text-h8 font-bold 
          text-primary-600 lg:mb-20 lg:w-auto lg:bg-transparent lg:text-h2 lg:text-gray-600">
          {feesRow}
        </h3>}
      <div className="rounded-lg border border-gray-200 px-6">
        {slides?.map((item, index) => <div onClick={() => {
        if (currentCoin == item.ticker) {
          setCurrentCoin('');
        } else {
          setCurrentCoin(item.ticker as string);
        }
      }} role="button" className="border-b border-gray-200 py-4 last:border-none" key={item.ticker}>
            <div className="flex items-center justify-between" key={item.ticker}>
              <div className="flex items-center gap-x-3">
                <PrismicNextImage className="h-7 w-7" field={item.icon} />
                <span>
                  {`${item.label}
                  (${item.ticker}/${item.currencyCode})`}
                </span>
              </div>
              <ChevronDownIcon className={clsx('h-4 w-4 transition-transform', currentCoin === item.ticker && 'rotate-180')} />
            </div>
            {item.ticker === currentCoin && <>
                <div className="space-y-4 py-5">
                  {centerRows[index]?.map(row => {
              return <div key={row.key} className="flex w-full justify-between">
                        <div className="flex">{row.key}</div>
                        <span className="text-h8">{row.value}</span>
                      </div>;
            })}
                </div>
                {!feesRow && <LinkButton variant="outline" className="w-full" field={variant === 'buyTable' || variant === 'buyFeesAndLimits' ? item.buyLink : item.sellLink}>
                    {buttonText[variant]} {item.ticker} <ArrowRightIcon />
                  </LinkButton>}
              </>}
          </div>)}
      </div>
    </div>;
};
export default MobileCrypto;