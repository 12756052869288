'use client';

import React, { HTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { ArrowRightIcon } from '@/public/assets/svg';
import Button from '@/components/atoms/Button';
import CalculatorDesktop from '@/components/molecules/CalculatorDesktop';
import CalculatorMobile from '@/components/molecules/CalculatorMobile';
import InteracFullLogo from './InteracFullLogo';
import { MAX_MONEY_AMOUNT, MIN_MONEY_AMOUNT } from './consts';
import { ROUTES } from '@/routes';
const CalculatorWithButton = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const [cadAmount, setCadAmount] = useState(100);
  const isButtonDisabled = !cadAmount || cadAmount < MIN_MONEY_AMOUNT || cadAmount > MAX_MONEY_AMOUNT;
  const router = useRouter();
  return <div className={clsx('w-full bg-white px-5 py-8 lg:p-12', className)} {...props} data-sentry-component="CalculatorWithButton" data-sentry-source-file="index.tsx">
      <CalculatorDesktop className="hidden lg:block" amount={cadAmount} setAmount={setCadAmount} data-sentry-element="CalculatorDesktop" data-sentry-source-file="index.tsx" />
      <CalculatorMobile className="block lg:hidden" amount={cadAmount} setAmount={setCadAmount} data-sentry-element="CalculatorMobile" data-sentry-source-file="index.tsx" />
      <div className="flex flex-col gap-5 lg:flex-row">
        <p className="order-2 mt-3 flex w-full text-body-8 text-gray-500 lg:order-1 lg:mb-3 lg:mt-0">
          * The floating rate can change at any point due to market conditions, so you might pay
          more or less crypto than expected.
        </p>
        <div className="order-1 flex w-full flex-col items-center justify-between lg:order-2 lg:flex-row">
          <InteracFullLogo className="hidden lg:flex" data-sentry-element="InteracFullLogo" data-sentry-source-file="index.tsx" />
          <Button variant="primary" disabled={isButtonDisabled} className="w-full lg:w-auto"
        // onMouseDown is used instead of onClick to prevent the issue with the button being active after onBlur
        onMouseDown={() => {
          router.push(ROUTES.onlineSellETransferRoot);
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <div className="flex flex-row items-center justify-center px-16 py-2.5">
              <span className="text-button font-medium text-gray-600">Continue</span>
              <ArrowRightIcon className="h-6 w-6 text-gray-600" data-sentry-element="ArrowRightIcon" data-sentry-source-file="index.tsx" />
            </div>
          </Button>
        </div>
        <InteracFullLogo className="order-3 flex lg:hidden" data-sentry-element="InteracFullLogo" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default CalculatorWithButton;