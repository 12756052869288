import { LOCALES } from '@/i18nConfig';

export const VERIFY_TOKEN_ENDPOINT = 'https://www.google.com/recaptcha/api/siteverify';
export const RECAPTCHA_HEADER = 'Recaptcha-Token';
export const APP_LOCALE_HEADER = 'x-lc-locale';

export const transactionApiUrl = (locale: string): string => {
  switch (locale) {
    case LOCALES.EN_AU:
      return `${process.env.TRANSACTION_BASE_AU_API_URL}`;
    case LOCALES.EN_HK:
      return `${process.env.TRANSACTION_BASE_HK_API_URL}`;
    default:
      return `${process.env.TRANSACTION_BASE_API_URL}`;
  }
};

export const transactionByIdUrl = (locale: string, transactionId: string): string =>
  `${transactionApiUrl(locale)}/${transactionId}`;

export const transactionSearchApiUrl = (locale: string): string =>
  `${transactionApiUrl(locale)}/search`;
