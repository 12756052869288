import Modal from '@/components/molecules/Modal';
import Button from '@/components/atoms/Button';
import { CloseIcon } from '@/public/assets/svg';
import { ModalInfoProps } from './types';
const ModalInfo = ({
  isOpen,
  title,
  description,
  onClose,
  buttonText = 'Okay'
}: ModalInfoProps) => {
  return <Modal {...{
    isOpen
  }} contentClassName="relative p-10 min-w-[30rem]" data-sentry-element="Modal" data-sentry-component="ModalInfo" data-sentry-source-file="index.tsx">
      <div className="flex flex-col items-center">
        <CloseIcon className="absolute right-4 top-4 h-6 w-6 cursor-pointer text-gray-600" onClick={() => onClose()} data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
        <h2 className="mb-3 text-body-3 font-bold">{title}</h2>
        <p className="mb-7 text-center text-body-7">{description}</p>
        <Button onClick={() => onClose()} className="w-[10.375rem]" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {buttonText}
        </Button>
      </div>
    </Modal>;
};
export default ModalInfo;