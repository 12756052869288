export const ATM_LISTING_GET_DIRECTIONS_CLICK_EVENT = 'listing-click-button-og';
export const ATM_LISTING_PHONE_NUMBER_CLICK_EVENT = 'listing-clicked-to-call-og';

export const ATM_MAP_STORES_LIST_ITEM_CLICK_EVENT = 'map-listing-click-og';
export const ATM_MAP_MARKER_CLICK_EVENT = 'map-marker-click-og';
export const ATM_MAP_SEARCH_EVENT = 'map-address-postal-map-search-og';
export const ATM_MAP_DIRECTIONS_CLICK_EVENT = 'map-listing-directions-click-og';
export const ATM_MAP_MARKER_DIRECTIONS_CLICK_EVENT = 'map-marker-directions-click-og';
export const ATM_MAP_VIEW_STORE_DETAILS_CLICK_EVENT = 'map-listing-view-store-details-link-click';

export const ATM_DETAILS_VIEW_NEAR_ATM_CLICK_EVENT = 'view-near-atm-page';
export const ATM_DETAILS_NEAR_ATM_DIRECTIONS_CLICK_EVENT = 'get-directions-nearby-atms-cards';
export const ATM_DETAILS_HOW_IT_WORKS_ATM_CLICK_EVENT = 'how-it-works-top-button-batm-page';
export const ATM_DETAILS_GET_DIRECTIONS_CLICK_EVENT = 'get-directions-button-top-batm-page';

export const SURVEY_POSITIVE_REVIEW_GMB_GTM_EVENT_NAME = 'positive-gmb-review-click';
export const SURVEY_NEGATIVE_REVIEW_GMB_GTM_EVENT_NAME = 'negative-gmb-review-click';
export const SURVEY_POSITIVE_REVIEW_DF_GTM_EVENT_NAME = 'direct-feedback-positive-click';
export const SURVEY_NEGATIVE_REVIEW_DF_GTM_EVENT_NAME = 'direct-feedback-negative-click';
